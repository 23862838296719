<template>
  <div
    class="flex w-9/12 mobile:w-full mx-auto px-16 flex-col pt-16 mobile:px-4"
  >
    <div class="flex justify-center mb-8">
      <span class="text-5xl font-semibold mobile:text-center">{{ title }}</span>
    </div>
    <div class="flex w-full pt-2 pb-6">
      <div class="flex w-2/12 mobile:w-6/12"></div>
      <div
        class="flex text-center w-5/12 mobile:w-3/12 justify-center font-medium text-2xl mobile:text-base mobile:items-center"
      >
        Baas
      </div>
      <div
        class="flex text-center w-5/12 mobile:w-3/12 justify-center font-medium text-2xl mobile:text-base mobile:items-center"
      >
        Premium
      </div>
    </div>
    <div class="flex w-full ">
      <div class="flex bg-offwhite w-full rounded-full items-center shadow">
        <div
          class="bg-pending rounded-full text-2xl text-white p-2 leading-none"
        >
          <img src="/pricing/gf.svg" class="h-5 w-5 filter-to-white" />
        </div>

        <span class="ml-2 font-semibold">Üldfunktsioonid</span>
      </div>
    </div>
    <feature-row
      feature-name="Kalendri import ja export"
      :tick-arr="[1, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Dokumendihaldus"
      :tick-arr="[1, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row feature-name="Märkmik" :tick-arr="[1, 1]"></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <div class="flex w-full py-4">
      <div
        class="flex w-2/12 mobile:w-6/12 border-r-2 border-green font-medium text-sm mobile:break-all items-center"
      >
        Salvestusruum
      </div>
      <div
        class="flex text-center w-5/12 mobile:w-3/12 justify-center border-r-2 border-green"
      >
        <span>1GB</span>
      </div>
      <div class="flex text-center w-5/12 mobile:w-3/12 justify-center">
        <span>10GB</span>
      </div>
    </div>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Täiendavad tööriistad iga kvartal"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full mb-8"></div>
    <div class="flex w-full">
      <div class="flex bg-offwhite w-full rounded-full items-center shadow">
        <div
          class="bg-pending rounded-full text-2xl text-white p-2 leading-none"
        >
          <img src="/pricing/pm.svg" class="h-5 w-5 filter-to-white" />
        </div>
        <span class="ml-2 font-semibold">Projektihaldus</span>
      </div>
    </div>
    <feature-row
      feature-name="Projektide loomine"
      :tick-arr="[1, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Täielik projektihaldus"
      :tick-arr="[1, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Ülesannete lisamine"
      :tick-arr="[1, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Ülesannete jälgimine kalendrist"
      :tick-arr="[1, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Korduvate ülesannete lisamine"
      :tick-arr="[1, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Piltide lisamine"
      :tick-arr="[1, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Failide lisamine"
      :tick-arr="[1, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Suhtlus meeskonnaga"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Suhtlus kliendiga"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Suhtlus projektijuhiga"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Õiguste määramine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full mb-8"></div>
    <div class="flex w-full">
      <div class="flex bg-offwhite w-full rounded-full items-center shadow">
        <div
          class="bg-pending rounded-full text-2xl text-white p-2 leading-none"
        >
          <img src="/pricing/em.svg" class="h-5 w-5 filter-to-white" />
        </div>
        <span class="ml-2 font-semibold">Töötajatehaldus</span>
      </div>
    </div>
    <feature-row
      feature-name="Töötajate lisamine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Töötajate määramine ülesannetele"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Tööaja mõõtmine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Palga arvestamine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Töötaja profiili haldamine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Töötajatel isiklik ligipääs"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Töötajatele failide ja info lisamine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full mb-8"></div>
    <div class="flex w-full">
      <div class="flex bg-offwhite w-full rounded-full items-center shadow">
        <div
          class="bg-pending rounded-full text-2xl text-white p-2 leading-none"
        >
          <img src="/pricing/inv.svg" class="h-5 w-5 filter-to-white" />
        </div>
        <span class="ml-2 font-semibold">Arved ja pakkumised</span>
      </div>
    </div>
    <feature-row
      feature-name="Pakkumiste esitamine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Arvete esitamine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Staatuste jälgimine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Kliendile ligipääsu andmine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Arvete jälgimine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Pakkumiste jälgimine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full mb-8"></div>
    <div class="flex w-full">
      <div class="flex bg-offwhite w-full rounded-full items-center shadow">
        <div
          class="bg-pending rounded-full text-2xl text-white p-2 leading-none"
        >
          <img src="/pricing/cli.svg" class="h-5 w-5 filter-to-white" />
        </div>
        <span class="ml-2 font-semibold">Kliendihaldus</span>
      </div>
    </div>
    <feature-row
      feature-name="Kliendiprofiilide haldamine"
      :tick-arr="[1, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row feature-name="Kliendiportaal" :tick-arr="[0, 1]"></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row feature-name="Kliendiotsing" :tick-arr="[0, 1]"></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Kliendile ligipääsu andmine"
      :tick-arr="[1, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Kliendi projektide jälgimine"
      :tick-arr="[1, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Kliendi arvete jälgimine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
    <feature-row
      feature-name="Kliendi pakkumiste jälgimine"
      :tick-arr="[0, 1]"
    ></feature-row>
    <div class="flex w-full bg-offwhite h-1 rounded-full"></div>
  </div>
</template>

<script>
import FeatureRow from "./featuresheet/FeatureRow";
export default {
  components: {
    FeatureRow
  },
  props: {
    title: {
      type: String,
      default: "Võimaluste nimekiri"
    }
  }
};
</script>
