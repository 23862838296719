<template>
  <div class="container-full flex-col">
    <pricing></pricing>
    <feature-sheet></feature-sheet>
    <features></features>
    <partner-feedback></partner-feedback>
  </div>
</template>

<script>
import Pricing from "@/components/public/Pricing";
import FeatureSheet from "@/components/public/pricing/FeatureSheet";
import Features from "@/components/public/product/Features";
import PartnerFeedback from "@/components/public/PartnerFeedback";
export default {
  components: {
    Pricing,
    FeatureSheet,
    PartnerFeedback,
    Features
  }
};
</script>
