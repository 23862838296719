<template>
  <div class="flex w-full py-4">
    <div
      class="flex w-2/12 mobile:w-6/12 border-r-2 border-green font-medium text-sm items-center"
    >
      {{ featureName }}
    </div>
    <div
      class="flex text-center w-5/12 mobile:w-3/12 justify-center border-r-2 border-green"
      v-html="getFill(tickArr[0])"
    ></div>
    <div
      class="flex text-center w-5/12 mobile:w-3/12 justify-center"
      v-html="getFill(tickArr[1])"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    featureName: String,
    tickArr: Array
  },
  methods: {
    getFill(input) {
      if (input === 1) {
        return '<span class="typcn typcn-tick text-2xl leading-none text-green"></span>';
      } else {
        return '<span class="typcn typcn-times text-2xl leading-none text-danger"></span>';
      }
    }
  }
};
</script>

<style></style>
